<template>
  <k-basic-modal-layout>
    <template v-slot:header>
      <h1 class="hasab-title">Felhasználó</h1>
    </template>
    <template v-slot:body>
      <b-form @submit.prevent="OnSubmit" :id="formId">
        <div class="container px-2">
          <div class="row no-gutters">
            <div class="col-6 pr-2">
              <b-form-group label="Név">
                <k-basic-text-input
                  v-model="$v.form.Nev.$model"
                  :validator="$v.form.Nev"
                  autofocus
                ></k-basic-text-input>
              </b-form-group>
            </div>
            <div class="col-6 pl-2">
              <b-form-group label="Email cím">
                <k-basic-text-input
                  v-model="$v.form.Email.$model"
                  :validator="$v.form.Email"
                ></k-basic-text-input>
              </b-form-group>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-6 pr-2">
              <b-form-group label="Telefonszám">
                <k-basic-text-input
                  v-model="$v.form.Telefonszam.$model"
                  placeholder="+3630 123-4567"
                  :validator="$v.form.Telefonszam"
                ></k-basic-text-input>
              </b-form-group>
            </div>
            <div class="col-6 pl-2">
              <b-form-group label="Hadrendi szám">
                <k-basic-text-input
                  v-model="$v.form.HadrendiSzam.$model"
                  :validator="$v.form.HadrendiSzam"
                ></k-basic-text-input>
              </b-form-group>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-6 pr-2">
              <b-form-group label="Szervezet:">
                <k-basic-multi-select
                  v-model="$v.form.Szervezet.$model"
                  :validator="$v.form.Szervezet"
                  :options="szervezetek"
                  required
                  labelBy="Text"
                ></k-basic-multi-select>
              </b-form-group>
            </div>
            <div class="col-6 pl-2">
              <b-form-group label="Jogosultság:">
                <k-basic-multi-select
                  v-model="$v.form.Jogosultsag.$model"
                  :validator="$v.form.Jogosultsag"
                  :options="jogosultsagCimkek"
                  required
                  labelBy="Text"
                ></k-basic-multi-select>
              </b-form-group>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-12">
              <b-form-group label="Cimkék:">
                <k-basic-multi-select
                  v-model="$v.form.Cimkek.$model"
                  :validator="$v.form.Cimkek"
                  :options="felhasznCimkek"
                  multiple
                  labelBy="Text"
                ></k-basic-multi-select>
              </b-form-group>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-12">
              <b-form-group label="Projektek:">
                <k-basic-multi-select
                  v-model="$v.form.Projektek.$model"
                  :validator="$v.form.Projektek"
                  :options="projektek"
                  :disabled="!isGod"
                  multiple
                  labelBy="Text"
                ></k-basic-multi-select>
              </b-form-group>
            </div>
          </div>
          <div class="col-12">
            <b-form-group>
              <b-form-checkbox v-model="form.ProjektAdmin" :disabled="!isGod">
                Projekt admin
              </b-form-checkbox>
            </b-form-group>
          </div>
        </div>
      </b-form>
    </template>
    <template v-slot:footer>
      <div class="footer justify-content-between">
        <k-button
          variant="dark"
          label="Mégsem"
          @click="Close()"
          icon="fas fa-times"
        ></k-button>
        <k-button
          class="ml-2"
          type="submit"
          variant="primary"
          label="Mehet"
          :form="formId"
          icon="fa-paper-plane"
          :loading="formSaving"
        ></k-button>
      </div>
    </template>
  </k-basic-modal-layout>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import required from 'vuelidate/src/validators/required';
import email from 'vuelidate/src/validators/email';
import numeric from 'vuelidate/src/validators/numeric';
import { mapGetters } from 'vuex';
import { AppStoreTypes } from '../../store/modules/app';
import { useModalHelper } from '../../utils/modal';
import { telefonszam } from '../../utils/validations';
import { userService } from '../../services/UserService';
import { hidrateForm } from '../../utils/common';
import { NotificationFunctions } from '../../functions/NotificationFunctions';
import { eventBus } from '../../main';
import { appService } from '../../services/AppService';
import { Felho } from '../../enums/Felho';
import { AppFunctions } from '../../functions/AppFunctions';
let defaultForm = {
  Id: 0,
  Nev: '',
  HadrendiSzam: '',
  Email: '',
  Szervezet: null,
  ProjektAdmin: false,
  Projektek: [],
  Jogosultsag: null,
  Telefonszam: '',
  Cimkek: []
};

export default {
  name: 'user-kezelo-modal',
  data() {
    return {
      form: cloneDeep(defaultForm),
      jogosultsagCimkek: [],
      felhasznCimkek: [],
      formSaving: false,
    };
  },
  setup(props, context) {
    let { formId, IsVueFormValid } = useModalHelper(props, context);
    return { formId, IsVueFormValid };
  },
  mounted() {
    this.LoadForm();
  },
  created() {},
  validations() {
    let validations = {
      form: {
        Nev: { required },
        HadrendiSzam: { required, numeric },
        Email: { required, email },
        Szervezet: { required },
        Projektek: { required },
        Jogosultsag: { required },
        Telefonszam: { required, telefonszam: telefonszam() },
        Cimkek: {}
      },
    };
    return validations;
  },
  methods: {
    Close() {
      this.$emit('close');
    },
    async LoadForm() {
      this.$emit('set-loading', true);
      try {
        if (this.userId != null && this.userId != -1) {
          let result = await userService.GetUser({
            id: this.userId,
          });
          hidrateForm(this, this.form, result);
        }
        this.jogosultsagCimkek = await appService.GetCimkekByFelhoId({
          felhoId: Felho.Jogosultsag,
          projektId: -1
        });
        this.felhasznCimkek = await appService.GetCimkekByFelhoId({
          felhoId: Felho.FelhasznaloiCimkek,
          projektId: null
        });
      } catch (error) {
        NotificationFunctions.AjaxError({ error });
        this.Close();
      }
      this.$emit('set-loading', false);
    },
    async OnSubmit() {
      if (!this.IsVueFormValid(this)) {
        console.log(this.$v);
        return;
      }
      this.formSaving = true;
      try {
        let data = { ...this.form, Id: this.userId };
        await userService.SaveUser(data);
        eventBus.$emit('data-update:user');
        NotificationFunctions.SuccessAlert(
          'Felhasználó kezelés',
          'Felhasználó adatai mentve'
        );
        this.Close();
      } catch (error) {
        NotificationFunctions.AjaxError({ error });
      }
      this.formSaving = false;
    },
  },

  computed: {
    ...mapGetters({
      user: AppStoreTypes.user.get,
      szervezetek: AppStoreTypes.szervezetek.getAll,
      jogosultProjektek: AppStoreTypes.jogosultProjektek.getAll,
    }),
    isProjektAdmin() {
      return AppFunctions.IsProjektAdmin(this.user);
    },
    isGod() {
      return AppFunctions.IsGod(this.user);
    },
    userId() {
      return this.modal?.data?.id ?? -1;
    },
    projektek() {
      return this.jogosultProjektek.map(m => {
        return { Id: m.Id, Text: m.Nev };
      });
    },
  },
  watch: {},
  props: {
    modal: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
};
</script>
